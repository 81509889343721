/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    code: "code",
    strong: "strong",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "migrate-to-v6",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#migrate-to-v6",
    "aria-label": "migrate to v6 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Migrate to v6"), "\n", React.createElement(_components.p, null, "This guide will help you migrating from SVGR v5.x to SVGR v6.x."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "webpack",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#webpack",
    "aria-label": "webpack permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "webpack"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "@svgr/webpack"), " is now ", React.createElement(_components.strong, null, "only compatible with webpack 5"), "."), "\n", React.createElement(_components.p, null, "It still works with deprecated ", React.createElement(_components.code, null, "url-loader"), " and ", React.createElement(_components.code, null, "file-loader"), " but it is recommended to use ", React.createElement(_components.a, {
    href: "https://webpack.js.org/guides/asset-modules/"
  }, "asset modules"), " available in webpack 5."), "\n", React.createElement(_components.p, null, "Report to ", React.createElement(_components.a, {
    href: "/docs/webpack/"
  }, "SVGR webpack documentation"), " to learn more about it."), "\n", React.createElement(_components.h2, {
    id: "svgo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#svgo",
    "aria-label": "svgo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SVGO"), "\n", React.createElement(_components.p, null, "SVGR v6 now uses SVGO v2 and does not merge config any more."), "\n", React.createElement(_components.p, null, "If you have a custom SVGO configuration (using a dedicated file or using ", React.createElement(_components.code, null, "svgo"), " key in SVGR config), then please follow ", React.createElement(_components.a, {
    href: "https://github.com/svg/svgo/releases/tag/v2.0.0"
  }, "SVGO migration guide"), "."), "\n", React.createElement(_components.p, null, "Configuration are not merged any more, it means you have to remove viewBox by yourself if you use a custom SVGO config with ", React.createElement(_components.code, null, "--icon"), " or ", React.createElement(_components.code, null, "--no-dimensions"), " option."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"name\": \"preset-default\",\n  \"params\": {\n    \"overrides\": {\n      \"removeViewBox\": false\n    }\n  }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "command-line-options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#command-line-options",
    "aria-label": "command line options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Command line options"), "\n", React.createElement(_components.p, null, "It is now recommended to add ", React.createElement(_components.code, null, "--"), " to separate files from arguments. Since ", React.createElement(_components.code, null, "--icon"), " can now accept an optional size, it could break your command if you have ", React.createElement(_components.code, null, "--icon"), " as the last argument."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "v5.x")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "svgr --icon assets/svg\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "v6.x")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "svgr --icon -- assets/svg\n")), "\n", React.createElement(_components.h2, {
    id: "template",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#template",
    "aria-label": "template permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Template"), "\n", React.createElement(_components.p, null, "Template signature has changed, it is now much more simpler to create custom templates. Also you don't have to do anything specific to use TypeScript in your template. If you need some Babel specific things you have to import it by yourself from the relevant Babel package."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "v5.x")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const template = (\n  { template },\n  opts,\n  { imports, interfaces, componentName, props, jsx, exports },\n) => {\n  const plugins = ['jsx']\n  if (opts.typescript) {\n    plugins.push('typescript')\n  }\n  const typeScriptTpl = template.smart({ plugins })\n  return typeScriptTpl.ast`${imports}\n\n${interfaces}\n\nfunction ${componentName}(${props}) {\n  return ${jsx};\n}\n${exports}\n  `\n}\n\nmodule.exports = template\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "v6.x")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const template = (variables, { tpl }) => {\n  return tpl`\n${variables.imports};\n\n${variables.interfaces};\n\nconst ${variables.componentName} = (${variables.props}) => (\n  ${variables.jsx}\n);\n \n${variables.exports};\n`\n}\n\nmodule.exports = template\n")), "\n", React.createElement(_components.h2, {
    id: "programmatic-usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#programmatic-usage",
    "aria-label": "programmatic usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Programmatic usage"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "@svgr/core"), " now exposes ", React.createElement(_components.code, null, "transform"), " as a named export instead of using the ", React.createElement(_components.code, null, "default"), " export."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "v5.x")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import transform from '@svgr/core'\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "v6.x")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { transform } from '@svgr/core'\n")), "\n", React.createElement(_components.h2, {
    id: "es-modules",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#es-modules",
    "aria-label": "es modules permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ES Modules"), "\n", React.createElement(_components.p, null, "SVGR v6 will be the last version to support CommonJS. If you are still using CommonJS you should ", React.createElement(_components.a, {
    href: "https://medium.com/sindre-sorhus/get-ready-for-esm-aa53530b3f77"
  }, "Get Ready For ESM"), " as soon as possible."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
